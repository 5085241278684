export interface PageContentProps {
  children: React.ReactNode
}

export const PageContent = ({ children }: PageContentProps) => {
  return (
    <main className='relative flex w-full flex-1 flex-col' role='main'>
      {children}
    </main>
  )
}
